import { clone } from "common/util";
import * as v from "app/variables";

// given paging values, builds a query string that can be passed to the API
export function buildPagingQuery(pageSize, firstRecord) {
  let pagingParams = "";
  if (
    typeof pageSize === "number" &&
    typeof firstRecord === "number" &&
    firstRecord >= 0
  ) {
    pagingParams = `pageSize=${pageSize}&firstRecord=${firstRecord}`;
  }
  return pagingParams;
}

// give an array of sort parameters, each of which contains 'dataField' and 'order',
// builds a sort query string that can be passed to the API
export function buildSortQuery(sort) {
  let sortParams = "";
  if (sort && sort.length > 0) {
    for (let i = 0; i < sort.length; i++) {
      if (sortParams !== "") {
        sortParams += ",";
      }

      // each entry should have a 'dataField' and an 'order'
      if (sort[i]["dataField"] && sort[i]["order"]) {
        sortParams +=
          sort[i]["dataField"] + ":" + sort[i]["order"].toUpperCase();
      }
    }

    sortParams = `sort=${sortParams}`;
  }
  return sortParams;
}

// given a dictionary, builds a search query string that can be passed to the API
export function buildSearchQuery(search) {
  // extracts a parameter from a JSON object, flattening keys if necessary
  const extractParams = (search, prefix = "") => {
    // process all params
    let searchParams = "";
    if (search) {
      for (let key in search) {
        if (!search[key] && typeof search[key] !== "boolean") {
          continue;
        }
        if (Array.isArray(search[key])) {
          // special case for arrays
          for (let i = 0; i < search[key].length; i++) {
            if (search[key][i] && search[key][i] !== "") {
              if (searchParams !== "") {
                searchParams += "&";
              }
              searchParams +=
                (prefix !== "" ? `${prefix}.${key}` : key) +
                "=" +
                encodeURI(search[key][i]);
            }
          }
        } else if (typeof search[key] === "object") {
          // objects need to go back through to be flattened
          if (searchParams !== "") {
            searchParams += "&";
          }
          searchParams += extractParams(
            search[key],
            prefix !== "" ? `${prefix}.${key}` : key
          );
        } else {
          // standard case
          if (search[key] !== "") {
            if (searchParams !== "") {
              searchParams += "&";
            }
            searchParams +=
              (prefix !== "" ? `${prefix}.${key}` : key) +
              "=" +
              encodeURI(search[key]);
          }
        }
      }

      // done
      return searchParams;
    }
  };

  return extractParams(search);
}

// give paging, sorting, and search parameters (all optional), builds a query URL
export function buildSearchURL(
  base,
  pageSize = v.defaultPageSize,
  firstRecord = 0,
  sort = [],
  search = {}
) {
  // build the paging argument
  const pagingParams = buildPagingQuery(pageSize, firstRecord);

  // build the sort argument
  const sortParams = buildSortQuery(sort);

  // build the search string
  const searchParams = buildSearchQuery(search);

  // build the URL
  let params = "";
  if (pagingParams && pagingParams !== "") {
    params += pagingParams;
  }
  if (sortParams && sortParams !== "") {
    if (params !== "") {
      params += "&";
    }
    params += sortParams;
  }
  if (searchParams && searchParams !== "") {
    if (params !== "") {
      params += "&";
    }
    params += searchParams;
  }
  return `${base}?${params}`;
}

// handles 'other' search fields by firing a 'not' query against all other options
export function handleOther(search, fieldName, options, otherValue = "other") {
  if (
    search &&
    search[fieldName] &&
    (search[fieldName] === otherValue || search[fieldName] === "xx")
  ) {
    // we're going to muck with it, so clone it
    search = clone(search);

    // invert all options
    const all = [];
    for (let key in options) {
      if (key !== search[fieldName]) {
        all.push("!" + key);
      }
    }
    search[fieldName] = all;
  }
  return search;
}
