import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";

import { BasePureComponent } from "common/components/Base";

/* Navigation redirection. */
class Navigate extends BasePureComponent {
  render() {
    // parent
    super.render();

    // fringe condition: navigating from a modal doesn't kill the backdrop
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();

    // supplemental props
    const s = this.props.supplemental ? this.props.supplemental : {};

    // this needs to stay in sync with the router mappings
    switch (this.props.destination) {
      case "home":
        return <Redirect to="/" push />;
      case "login":
        // make sure we don't nest redirects
        let redirect =
          s.redirect && !s.redirect.startsWith("/~%2F")
            ? "~" + encodeURIComponent(s.redirect)
            : s.redirect
            ? s.redirect
            : "";

        // prefix the redirect
        redirect = (redirect.startsWith("/") ? "" : "/") + redirect;

        // do it
        return <Redirect to={redirect} push />;
      case "profile":
        return <Redirect to="/account" push />;
      case "siteStaffApplications":
        return <Redirect to="/applications" push />;
      case "siteStaffEmployeeRecords":
        return <Redirect to="/employeeRecords" push />;
      default:
        return null;
    }
  }
}

// set prop types and required-ness
Navigate.propTypes = {
  destination: PropTypes.string.isRequired,
  supplemental: PropTypes.object,
};

// set default props
Navigate.defaultProps = {};

export default Navigate;
