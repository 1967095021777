//
// This file contains application-specific variables, some of which
// are pulled via callbacks from common components.
//

// default page title
export const defaultTitle = "FSPConnect - School Portal";

// default size for form widgets, in Bootstrap parlance ('sm' or 'lg')
export const formWidgetSize = "sm";

// default size for buttons, in Bootstrap parlance ('sm' or 'lg')
export const formButtonSize = "sm";

// default page size for lists
export const defaultPageSize = 10;

// max size for résumés, in bytes
export const maxResumeSize = 1048576;

// include weekends in calendar-based functionality?
export const includeWeekends = false;

// the type of entity that uses this application
export const entityType = "siteStaff";

// the time when daily feedback is available for submission
export const feedbackOpen = "1:00";
